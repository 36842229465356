<template>
  <div>
    <div
      v-for="(group, groupIndex) in model"
      :key="`trip-vehicle-group-${groupIndex}`"
    >
      <h6 v-if="group.name">{{ group.name }}</h6>
      <div
        v-for="(assignment, i) in group.vehicleAssignments"
        :key="`assignment-group-${i}`"
      >
        <!-- START VEHICLE SELECT -->
        <div class="dispatch-sidebar--assignment-input-box">
          <div style="flex-basis: 85%">
            <label>{{ assignment.vehicleTypeLabel }}</label>
            <a
              color="$cr.theme.primary"
              style="font-size: 13px; float: right"
              @click="
                updateVehicleModel(assignment, groupIndex, i, {
                  vehicleId: null,
                })
              "
            >
              Clear Assignment
            </a>
          </div>
          <div style="flex-basis: 85%">
            <div style="width: 100%">
              <v-autocomplete
                :id="`vehicle-select-${i}`"
                placeholder="No Vehicle Selected"
                :items="
                  availableVehicles.length
                    ? availableVehicles.filter((v) => {
                        if (!v.name && v.vehicleName && v.vehicleModel) {
                          v.name = `${v.vehicleName} ${v.vehicleModel}`
                        }
                        return v.vehicleTypeId === assignment.vehicleTypeId
                      })
                    : availableVehicles
                "
                return-object
                :color="$cr.theme.grayBase"
                style="flex-grow: 0"
                solo
                flat
                item-value="vehicleId"
                :value="assignment.vehicle"
                append-icon="keyboard_arrow_down"
                :error-messages="getErrorMessagesIfInvalidVehicle(assignment.vehicle)"
                @input="updateVehicleModel(assignment, groupIndex, i, $event)"
              >
                <template #item="{ item }">
                  <v-tooltip
                    right
                    nudge-bottom="30px"
                    nudge-right="-75px"
                    :disabled="!item.hardConflict"
                  >
                    <template #activator="vehicleOn">
                      <v-layout row justify-space-between v-on="vehicleOn.on">
                        <span>{{ item.name }}</span>

                        <span v-if="item.hardConflict">
                          <CRIcon
                            material
                            color="error"
                            style="pointer-events: none"
                          >
                            error_color
                          </CRIcon>
                        </span>
                      </v-layout>
                    </template>
                    <span v-if="item.hardConflict">Hard Conflict</span>
                  </v-tooltip>
                </template>
                <template #selection="{ item }">
                  {{ item.name }}
                  <span class="px-1">
                    <ReservationVehicleTrackingIcon
                      :vehicle-id="item.vehicleId"
                      :vehicle-type-name="item.vehicleTypeName"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </div>
          </div>
          <div style="width: 11.5%">
            <v-tooltip top>
              <template #activator="action">
                <span
                  v-show="assignment.vehicle && assignment.vehicle.hardConflict"
                  style="cursor: pointer"
                  v-on="action.on"
                  @click="setShowConflictDetail(true)"
                >
                  <CRIcon
                    color="error"
                    material
                    :height="36"
                    :width="36"
                    style="pointer-events: none"
                  >
                    error_color
                  </CRIcon>
                </span>
              </template>
              <span>Click to see Conflict Details</span>
            </v-tooltip>
          </div>
        </div>

        <!-- START DRIVER SELECT -->
        <div
          v-for="(driver, j) in assignment.driverAssignments"
          :key="`driver-selection-div-${j}`"
          class="dispatch-sidebar--assignment-input-box"
        >
          <div style="flex-basis: 85%">
            <label :key="`driver-select-label-${j}`">Driver</label>
            <a
              id="dispatch-clear-assignment-link"
              color="$cr.theme.primary"
              style="font-size: 13px; float: right"
              @click="updateDriverModel(assignment, groupIndex, i, j, {})"
            >
              Clear Assignment
            </a>
            <v-layout row justify-space-between>
              <a
                v-if="
                  hasCustomerInfo &&
                  assignment.driverAssignments[j].driver &&
                  assignment.driverAssignments[j].driver.userId
                "
                id="dispatch-send-driver-info-link"
                :class="`${
                  disabledDriverButtons(groupIndex, i) ? 'disabled-button' : ''
                }`"
                color="$cr.theme.primary"
                style="font-size: 13px"
                @click="setShowSendDriverInfo(true)"
              >
                Send Driver Info
              </a>
            </v-layout>
          </div>
          <div style="flex-basis: 85%">
            <div style="width: 100%">
              <v-tooltip bottom left>
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-autocomplete
                      :id="`driver-select-${i}-${j}`"
                      :key="`driver-select-${j}`"
                      :items="activeAvailableDrivers"
                      label="Driver"
                      placeholder="No Driver Selected"
                      style="flex-grow: 0"
                      solo
                      flat
                      :color="$cr.theme.blackBase"
                      item-text="name"
                      item-value="userId"
                      return-object
                      hide-details
                      :value="assignment.driverAssignments[j].driver"
                      append-icon="keyboard_arrow_down"
                      @input="
                        updateDriverModel(assignment, groupIndex, i, j, $event)
                      "
                    >
                      <template #item="{ item }">
                        <v-tooltip
                          right
                          nudge-bottom="30px"
                          nudge-right="-75px"
                          :disabled="!item.hardConflict"
                        >
                          <template #activator="driverOn">
                            <v-layout
                              row
                              justify-space-between
                              v-on="driverOn.on"
                            >
                              <span>{{ item.name }}</span>
                              <span v-if="item.hardConflict">
                                <CRIcon
                                  material
                                  color="error"
                                  class="icon-error"
                                  style="pointer-events: none"
                                >
                                  error_color
                                </CRIcon>
                              </span>
                            </v-layout>
                          </template>
                          <span v-if="item.hardConflict">Hard Conflict</span>
                        </v-tooltip>
                      </template>
                    </v-autocomplete>
                  </span>
                </template>
                <span v-if="!!assignment.driverAssignments[j].driver.name">
                  {{ assignment.driverAssignments[j].driver.name }}
                  <br />
                  {{
                    getDriverPhoneNumber(assignment.driverAssignments[j].driver)
                  }}
                </span>
                <span v-else>No Driver Selected</span>
              </v-tooltip>
            </div>
          </div>
          <div style="width: 10%">
            <CRIcon
              v-if="
                assignment.driverAssignments[j].driver &&
                assignment.driverAssignments[j].driver.userId
              "
              id="dispatch-copy-driver-info-link"
              view-box="0 0 18 18"
              color="primary"
              @click.native="callCopy(assignment.driverAssignments[j].driver)"
            >
              driver_info
            </CRIcon>
          </div>
          <div style="flex-basis: 85%">
            <div style="width: 100%">
              <CRInput
                v-if="
                  assignment.driverAssignments[j].driver &&
                  assignment.driverAssignments[j].driver.userId
                "
                :id="`driver-number-${i}-${j}`"
                v-model="assignment.driverAssignments[j].driver.phone"
                :disabled="!enabled[`${i},${j}`]"
                style="flex-grow: 0"
                solo
                hide-details
                flat
                :color="$cr.theme.blackBase"
                type="text"
              />
            </div>
          </div>
          <div style="width: 10%">
            <CRIcon
              v-if="
                assignment.driverAssignments[j].driver &&
                assignment.driverAssignments[j].driver.userId &&
                !enabled[`${i},${j}`]
              "
              id="edit-driver-number"
              :key="`edit-driver-number-key-${editToggleComponent}`"
              view-box="0 0 24 24"
              color="primary"
              small
              @click.native="startEditingNumber(i, j)"
            >
              edit
            </CRIcon>
            <CRIcon
              v-if="
                assignment.driverAssignments[j].driver &&
                assignment.driverAssignments[j].driver.userId &&
                enabled[`${i},${j}`] === true
              "
              id="confirm-driver-number"
              :key="`confirm-driver-number-key-${editToggleComponent}`"
              view-box="0 0 24 24"
              color="success"
              @click.native="
                finishEditingDriver(
                  i,
                  j,
                  assignment.driverAssignments[j].driver
                )
              "
            >
              check
            </CRIcon>
          </div>
          <div style="width: 100%"></div>
          <!-- Conflict Icon -->
          <v-tooltip :key="`driver-tooltip-${j}`" top>
            <template #activator="action">
              <span
                v-show="
                  assignment.driverAssignments[j].driver &&
                  assignment.driverAssignments[j].driver.hardConflict
                "
                style="cursor: pointer"
                v-on="action.on"
                @click="setShowConflictDetail(true)"
              >
                <CRIcon
                  color="error"
                  material
                  :height="36"
                  :width="36"
                  style="pointer-events: none"
                >
                  error_color
                </CRIcon>
              </span>
            </template>
            <span>Click to see Conflict Details</span>
          </v-tooltip>
        </div>
        <div
          v-if="model.length === 1 && i < group.vehicleAssignments.length - 1"
          class="dispatch-sidebar--assignment-divider"
        ></div>
      </div>
      <div
        v-if="groupIndex < model.length - 1"
        class="dispatch-sidebar--assignment-divider"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { copyDriverInfo } from '@/utils/driver'
import { EventBus } from '@/utils/event-bus'
import { getDriverPhoneNumber } from '@/utils/tripAssignments'
import ReservationVehicleTrackingIcon from './ReservationVehicleTrackingIcon.vue'
import CRIcon from '@/cr/components/CRIcon.vue'
import drivers from '@/services/drivers'
import { VinVerificationType } from '@/utils/enum'

export default {
  components: {
    ReservationVehicleTrackingIcon,
    CRIcon,
  },
  props: {
    drivers: {
      type: Array,
      default: () => [],
    },
    vehicles: {
      type: Array,
      default: () => [],
    },
    availableDrivers: {
      type: Array,
      default: () => [],
    },
    availableVehicles: {
      type: Array,
      default: () => [],
    },
    reservation: {
      type: Object,
      default: () => ({}),
    },
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      driverInfoChanged: [],
      enabled: {},
      editToggleComponent: 0,
    }
  },
  computed: {
    ...mapGetters({
      assignmentsLoading: 'dispatchNew/assignmentsLoading',
      showConflictDetail: 'dispatchNew/showConflictDetail',
      showSendDriverInfo: 'dispatchNew/showSendDriverInfo',
    }),
    hasCustomerInfo() {
      return !!this.reservation?.trip?.customer
    },
    activeAvailableDrivers() {
      return this.availableDrivers
        .filter((driver) => driver.active)
        .map((driver) => {
          return { ...driver, name: `${driver.firstName} ${driver.lastName}` }
        })
    },
  },
  mounted() {
    this.driverInfoChanged = this.model.map((e) => false)
    EventBus.$on('saved-reservation', () => {
      this.driverInfoChanged = this.driverInfoChanged.map((e) => false)
    })
  },
  methods: {
    getErrorMessagesIfInvalidVehicle(vehicle) {
      if (vehicle?.vinVerificationType?.key == VinVerificationType.VerificationFailure) {
        return "VIN verification failed for this vehicle"
      }
      if (vehicle?.vinVerificationType?.key == VinVerificationType.VerificationPending) {
        return "VIN verification pending for this vehicle"
      }
      return []
    },
    getDriverPhoneNumber,
    ...mapActions({
      setLoading: 'dispatchNew/setLoading',
      setShowConflictDetail: 'dispatchNew/setShowConflictDetail',
      setShowSendDriverInfo: 'dispatchNew/setShowSendDriverInfo',
    }),
    disabledDriverButtons(groupIndex, index) {
      return (
        !this.model[groupIndex].vehicleAssignments[index]?.driverAssignments
          ?.length || this.driverInfoChanged[index]
      )
    },
    updateVehicleModel(
      existingAssignment,
      groupIndex,
      assignmentIndex,
      newVehicle
    ) {
      existingAssignment.vehicle = newVehicle
      this.$emit('update-assignment', {
        index: assignmentIndex,
        groupIndex,
        assignment: existingAssignment,
      })
    },
    updateDriverModel(
      existingAssignment,
      groupIndex,
      assignmentIndex,
      driverIndex,
      newDriver
    ) {
      let { driverAssignments } = existingAssignment
      newDriver.phone = getDriverPhoneNumber(newDriver)
      driverAssignments[driverIndex].driver = newDriver
      driverAssignments[driverIndex].userId = newDriver.userId
      let newAssignment = { ...existingAssignment, driverAssignments }
      this.$emit('update-assignment', {
        index: assignmentIndex,
        groupIndex,
        assignment: newAssignment,
      })
      this.driverInfoChanged[driverIndex] = true
    },
    callCopy(driver) {
      if (!driver || !driver?.userId) return

      this.$store.dispatch(
        'app/showAlert',
        copyDriverInfo(driver?.userId, this.drivers)
      )
    },
    startEditingNumber(i, j) {
      this.enabled[`${i},${j}`] = true
      this.editToggleComponent += 1
    },
    async finishEditingDriver(i, j, driver) {
      this.editToggleComponent += 1
      this.enabled[`${i},${j}`] = false
      // Check if the numbers are different
      const newNum = driver.phone.replace(/[^0-9]/gi, '')

      // call update endpoint
      await drivers.modifyDriverV3({
        id: driver.userId,
        payload: { phoneNumber: newNum },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dispatch-sidebar {
  h4 {
    font-size: 24px;
  }

  &--assignment-input-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;

    svg {
      margin-bottom: 5px;
    }
  }

  &--assignment-divider {
    min-width: 100%;
    height: 1px;
    margin-bottom: 34px;
    margin-top: 10px;
    background: $dispatch-gray;
  }
}

.disabled-button {
  color: $dispatch-gray;
  cursor: default;
  pointer-events: none;
}

::v-deep .v-autocomplete input {
  color: $gray-base !important;
}
</style>
